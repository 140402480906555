<template>
  <v-dialog
    v-model="show"
    :max-width="max_width"
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <v-card>
                <v-row>
                  <v-col>
                    {{ $t('container.selected') }}:{{ item.boxes?item.boxes.length:0 }}
                  </v-col>
                  <v-col>{{ $t('container.total_gross_weight') }}:{{ fixedFloat(selectedWeight,2,0) }}</v-col>
                  <v-col>{{ $t('container.total_cubic_feet') }}:{{ fixedFloat(selectedCubicfeet),2,0 }}</v-col>
                  <v-col>{{ $t('container.total_amount') }}:{{ fixedFloat(selectedAmount,2,0) }}</v-col>
                </v-row>
              </v-card>
              <v-card class="my-4">
                <v-row>
                  <!-- <v-col>
                    <CommonAutocomplete
                      v-model="deliver_type"
                      :label="$t('commodity.deliver-type')"
                      url="/commodity/deliver-type/"
                      :item_text="nameField"
                      item_value="id"
                      :query_field="nameFieldFilter"
                      :cache="[deliver_type_cache]"
                      :extra_condition="{
                        enable: true
                      }"
                    ></CommonAutocomplete>
                  </v-col> -->
                  <v-col>
                    <CommonAutocomplete
                      v-model="deliver_suject"
                      :label="$t('commodity.deliver-type')"
                      url="/commodity/deliver-suject/"
                      :item_text="nameField"
                      item_value="id"
                      :query_field="nameFieldFilter"
                      :cache="[deliver_suject_cache]"
                      :extra_condition="{
                        enable: true
                      }"
                    ></CommonAutocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col style="display: flex;">
                    <div>
                      <v-checkbox v-model="deliver_unit_amount" :label="$t('unit.pieces')"></v-checkbox></div>
                    <div><v-text-field v-model="deliver_unit_amount_fee"></v-text-field></div>
                  </v-col>
                  <v-col style="display: flex;">
                    <div>
                      <v-checkbox v-model="deliver_unit_weight" :label="$t('unit.weight')"></v-checkbox></div>
                    <div><v-text-field v-model="deliver_unit_weight_fee"></v-text-field></div>
                  </v-col>
                  <v-col style="display: flex;">
                    <div>
                      <v-checkbox v-model="deliver_unit_cubicfeet" :label="$t('unit.cubic-feet')"></v-checkbox></div>
                    <div><v-text-field v-model="deliver_unit_cubicfeet_fee"></v-text-field></div>
                  </v-col>
                  <v-col style="display: flex;">
                    <div>
                      <v-checkbox v-model="deliver_unit_package" :label="$t('unit.package')"></v-checkbox></div>
                    <div><v-text-field v-model="deliver_unit_package_fee"></v-text-field></div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="remark"
                      :label="$t('container-box.remark')"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="close">{{ $t("cancel") }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-if="action != action_items.view"
          color="primary"
          :disabled="allowSave"
          @click="save"
        >{{ $t("save") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import BaseDialog from "./BaseDialog";
import CommonAutocomplete from "../filters/CommonAutocomplete";
export default {
  props: {
    selected: {
      type: Array,
      default: function () {
        return [];
      },
    },
    selectedWeight: 0,
    selectedCubicfeet: 0,
    selectedAmount: 0,
  },
  data: (vm) => ({
    topic: vm.$i18n.t("container-box.extra"),
    deliver_type: null,
    deliver_type_cache: null,
    deliver_suject: null,
    deliver_suject_cache: null,
    deliver_unit_amount: false,
    deliver_unit_amount_fee: null,
    deliver_unit_weight: false,
    deliver_unit_weight_fee: null,
    deliver_unit_cubicfeet: false,
    deliver_unit_cubicfeet_fee: null,
    deliver_unit_package: false,
    deliver_unit_package_fee: null,
    remark: null,
  }),
  computed: {
    allowSave() {
      return false;
    },
  },
  mixins: [BaseDialog],
  components: {
    CommonAutocomplete,
  },
  methods: {
    save(){
      console.log(this.item)
      // this.item['deliver_type'] = this.deliver_type
      this.item['deliver_suject'] = this.deliver_suject

      this.item['deliver_unit_amount'] = this.deliver_unit_amount
      this.item['deliver_unit_amount_fee'] = this.deliver_unit_amount_fee
      this.item['deliver_unit_weight'] = this.deliver_unit_weight
      this.item['deliver_unit_weight_fee'] = this.deliver_unit_weight_fee
      this.item['deliver_unit_cubicfeet'] = this.deliver_unit_cubicfeet
      this.item['deliver_unit_cubicfeet_fee'] = this.deliver_unit_cubicfeet_fee
      this.item['deliver_unit_package'] = this.deliver_unit_package
      this.item['deliver_unit_package_fee'] = this.deliver_unit_package_fee

      let extra = {}
      if (this.remark) {
        extra['remark'] = this.remark
      }
      this.item['extra'] = JSON.stringify(extra)
      this.resetFields()
      this.$emit('onSave',this.item)
    },
    resetFields(){
      this.deliver_type= null
      this.deliver_type_cache= null
      this.deliver_suject= null
      this.deliver_suject_cache= null
      this.deliver_unit_amount= false
      this.deliver_unit_amount_fee= null
      this.deliver_unit_weight= false
      this.deliver_unit_weight_fee= null
      this.deliver_unit_cubicfeet= false
      this.deliver_unit_cubicfeet_fee= null
      this.deliver_unit_package= false
      this.deliver_unit_package_fee= null
    },
    close() {
      this.resetFields()
      this.$emit("onClose");
    },
  },
  watch: {
    show: {
      handler(val){},
    },
  },
}
</script>
