<template>
  <v-container fluid>
    <vue-easy-lightbox
      :visible="lightboxVisible"
      :imgs="images"
      @hide="closeLightBox"
    ></vue-easy-lightbox>
    <DeliverTypeFastCreateDialog
      :show="showDialog"
      :action="dialogAction"
      :item="dialogItem"
      v-on:onClose="onCloseEvent"
      v-on:onSave="onSaveEvent"
    ></DeliverTypeFastCreateDialog>
    <DeliverSujectFastCreateDialog
      :show="showDialog2"
      :action="dialogAction2"
      :item="dialogItem2"
      v-on:onClose="onCloseEvent2"
      v-on:onSave="onSaveEvent2"
    ></DeliverSujectFastCreateDialog>
    <DeliverFeeExtraDialog
      :show="showDialog3"
      :action="dialogAction3"
      :item="dialogItem3"
      v-on:onClose="onCloseEvent3"
      v-on:onSave="onSaveEvent3"
    ></DeliverFeeExtraDialog>
    <DeliverFeeVerifyDialogVue
      :show="showDialog4"
      :action="dialogAction4"
      :item="dialogItem4"
      :selectedAmount="selectedAmount"
      :selectedCubicfeet="selectedCubicfeet"
      :selectedWeight="selectedWeight"
      v-on:onClose="onCloseEvent4"
      v-on:onSave="onSaveEvent4"
    ></DeliverFeeVerifyDialogVue>
    <DeliverfeeVerifyFilter
      v-on:onFilterChange="onFilterChange"
      :container_items="container_items"
    ></DeliverfeeVerifyFilter>
    <v-tabs>
      <v-tab @click="target_check_options=check_options.uncheck_tw">{{ $t('container-box.uncheck-tw') }}</v-tab>
      <v-tab @click="target_check_options=check_options.uncheck_cn">{{ $t('container-box.uncheck-cn') }}</v-tab>
      <v-tab @click="target_check_options=check_options.check">{{ $t('container-box.checked') }}</v-tab>
    </v-tabs>
    
    <base-material-card
      icon="mdi-clipboard-text"
      :title="$t('container.deliver-fee-verify')"
      class="px-5 py-3"
    >
      <v-col class="text-right">
        <v-btn
          color="primary"
          dark
          class="mb-2 ml-4"
          @click="multipleUpdate"
        >
          <v-icon>mdi-checkbox-multiple-marked</v-icon>
          {{ $t("container.deliver-fee-verify-multi") }}
        </v-btn>
        <!-- <v-btn
          color="primary"
          dark
          class="mb-2 ml-4"
          @click="createDeliverType"
        >
          <v-icon>mdi-plus</v-icon>
          {{ $t("create") }}{{ $t("commodity.deliver-type") }}
        </v-btn> -->
        <v-btn
          color="primary"
          dark
          class="mb-2 ml-4"
          @click="createDeliverSuject"
        >
          <v-icon>mdi-plus</v-icon>
          <!-- {{ $t("create") }}{{ $t("commodity.deliver-suject") }} -->
          {{ $t("create") }}{{ $t("commodity.deliver-type") }}
        </v-btn>
      </v-col>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        :footer-props="footerProps"
        show-select
        multi-sort
        fixed-header
        :height="windowHeight*0.8"
      >
        <template v-slot:top="{pagination, options, updateOptions}">
          <v-data-footer 
            :pagination="pagination" 
            :options="options"
            @update:options="updateOptions"
            :show-first-last-page="true"
            :show-current-page="true"
            first-icon="mdi-chevron-double-left"
            last-icon="mdi-chevron-double-right"
            prev-icon="mdi-chevron-left"
            next-icon="mdi-chevron-right"
            :items-per-page-options="[20, 50, 100, 200]"
          />
        </template>
        <template v-slot:item.end_box="{ item }">
          {{ get_end_box(item) }}
        </template>
        <template v-slot:item.commodity_name="{ item }">
          <template v-for="data in item.relation">
            <div>{{ data.commodity.name ? data.commodity.name : '&nbsp;' }}</div>
          </template>
        </template>
        <template v-slot:item.commodity_number="{ item }">
          <template v-for="data in item.relation">
            <div style="display: flex">
              <div>{{ data.commodity.commodity_number }}</div>
            </div>
          </template>
        </template>
        <template v-slot:item.image="{ item }">
          <template v-for="data in item.relation">
            <template v-if="data.commodity.image">
              <v-img
                class="mr-2"
                style="cursor: pointer"
                :src="get_image(data)"
                max-width="100"
                v-on="on"
                @click="displayCommodityImage(item)"
              ></v-img>
            </template>
            <template v-else>
              <v-icon
                large
                class="mr-2"
              >mdi-tooltip-image</v-icon>
            </template>
          </template>
        </template>
        <template v-slot:item.customs_type="{ item }">
          <template v-for="data in item.relation">
            <div>{{ get_customs_type_name(data) }}</div>
          </template>
        </template>
        <template v-slot:item.total_amount="{ item }">
          {{ fixedFloat(get_total_amount(item), 0, 0) }}
        </template>
        <template v-slot:item.total_gross_weight="{ item }">
          {{ fixedFloat(get_total_gross_weight(item), 2, 0) }}
        </template>
        <template v-slot:item.supplier="{ item }">
          {{ get_supplier_name(item) }}
        </template>
        <template v-slot:item.customer__name_tc="{ item }">
          {{ get_customer_name(item) }}
        </template>
        <template v-slot:item.deliver_type="{ item }">
          <template v-if="target_check_options==check_options.uncheck_tw||target_check_options==check_options.uncheck_cn">
            <v-edit-dialog
              @save="updateField(item.id,'deliver_type',deliver_type)"
              @open="editOpen(item.deliver_type,1)"
              @close="editClose()"
              :save-text="$t('save')"
              :cancel-text="$t('cancel')"
              large
            >
              {{ get_deliver_type(item.deliver_type) }} <v-icon>mdi-pencil</v-icon>
              <template v-slot:input>
                <CommonAutocomplete
                  v-model="deliver_type"
                  url="/commodity/deliver-type/"
                  :item_text="nameField"
                  item_value="id"
                  :query_field="nameFieldFilter"
                  :cache="[deliver_type_cache]"
                  :extra_condition="{
                    enable: true
                  }"
                ></CommonAutocomplete>
              </template>
            </v-edit-dialog>
          </template>
          <template v-else>
            {{ get_deliver_type(item.deliver_type) }}
          </template>
        </template>
        <template v-slot:item.deliver_suject="{ item }">
          <template v-if="target_check_options==check_options.uncheck_tw||target_check_options==check_options.uncheck_cn">

            <v-edit-dialog
              @save="updateField(item.id,'deliver_suject',deliver_suject)"
              @open="editOpen(item.deliver_suject,2)"
              @close="editClose()"
              :save-text="$t('save')"
              :cancel-text="$t('cancel')"
              large
            >
              {{ get_deliver_type(item.deliver_suject) }} <v-icon>mdi-pencil</v-icon>
              <template v-slot:input>
                <CommonAutocomplete
                  v-model="deliver_suject"
                  url="/commodity/deliver-suject/"
                  :item_text="nameField"
                  item_value="id"
                  :query_field="nameFieldFilter"
                  :cache="[deliver_suject_cache]"
                  :extra_condition="{
                    enable: true
                  }"
                ></CommonAutocomplete>
              </template>
            </v-edit-dialog>
          </template>
          <template v-else>
            {{ get_deliver_type(item.deliver_suject) }}
          </template>
        </template>
        <template v-slot:item.deliver_fee="{ item }">
          <template v-if="target_check_options==check_options.uncheck_tw||target_check_options==check_options.uncheck_cn">

            <v-edit-dialog
              @save="updateField(item.id,'deliver_fee',item.deliver_fee)"
              :save-text="$t('save')"
              :cancel-text="$t('cancel')"
              large
            >
              {{ item.deliver_fee }} <v-icon>mdi-pencil</v-icon>
              <template v-slot:input>
                <v-text-field v-model="item.deliver_fee"></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <template v-else>
            {{ item.deliver_fee }}
          </template>
        </template>
        <template v-slot:item.commodity.deliver_suject="{ item }">
          {{ item.commodity.deliver_suject ? item.commodity.deliver_suject : '-' }}
        </template>
        <template v-slot:item.deliver_unit="{ item }">
          <div style="display: flex;">
            <div>
              <v-checkbox dense v-model="item.deliver_unit_amount" :label="$t('unit.pieces')" @change="updateField(item.id,'deliver_unit_amount',item.deliver_unit_amount)"></v-checkbox>
            </div>
            <div style="width: 50px">
              <v-edit-dialog
                @save="updateField(item.id,'deliver_unit_amount_fee',item.deliver_unit_amount_fee)"
                :save-text="$t('save')"
                :cancel-text="$t('cancel')"
                large
              >
                <v-text-field dense v-model="item.deliver_unit_amount_fee" outlined width="40px"></v-text-field>
                <!-- <v-icon>mdi-pencil</v-icon> -->
                <template v-slot:input>
                  <v-text-field v-model="item.deliver_unit_amount_fee"></v-text-field>
                </template>
              </v-edit-dialog>
              
            </div>
          </div>
          <div style="display: flex;">
            <div>
              <v-checkbox dense v-model="item.deliver_unit_weight" :label="$t('unit.weight')" @change="updateField(item.id,'deliver_unit_weight',item.deliver_unit_weight)"></v-checkbox>
            </div>
            <div style="width: 50px">
              <v-edit-dialog
                @save="updateField(item.id,'deliver_unit_weight_fee',item.deliver_unit_weight_fee)"
                :save-text="$t('save')"
                :cancel-text="$t('cancel')"
                large
              >
                <v-text-field dense v-model="item.deliver_unit_weight_fee" outlined width="40px"></v-text-field>
                <!-- <v-icon>mdi-pencil</v-icon> -->
                <template v-slot:input>
                  <v-text-field v-model="item.deliver_unit_weight_fee"></v-text-field>
                </template>
              </v-edit-dialog>
              
            </div>
          </div>
          <div style="display: flex;">
            <div>
              <v-checkbox dense v-model="item.deliver_unit_cubicfeet" :label="$t('unit.cubic-feet')" @change="updateField(item.id,'deliver_unit_cubicfeet',item.deliver_unit_cubicfeet)"></v-checkbox>
            </div>
            <div style="width: 50px">
              <v-edit-dialog
                @save="updateField(item.id,'deliver_unit_cubicfeet_fee',item.deliver_unit_cubicfeet_fee)"
                :save-text="$t('save')"
                :cancel-text="$t('cancel')"
                large
              >
                <v-text-field dense v-model="item.deliver_unit_cubicfeet_fee" outlined width="40px"></v-text-field>
                <template v-slot:input>
                  <v-text-field v-model="item.deliver_unit_cubicfeet_fee"></v-text-field>
                </template>
              </v-edit-dialog>
              
            </div>
          </div>
          <div style="display: flex;">
            <div>
              <v-checkbox
                dense v-model="item.deliver_unit_package"
                :label="$t('unit.package')"
                @change="updateField(item.id,'deliver_unit_package',item.deliver_unit_package)"
              ></v-checkbox>
            </div>
            <div style="width: 50px">
              <v-edit-dialog
                @save="updateField(item.id,'deliver_unit_package_fee',item.deliver_unit_package_fee)"
                :save-text="$t('save')"
                :cancel-text="$t('cancel')"
                large
              >
                <v-text-field dense v-model="item.deliver_unit_package_fee" outlined width="40px"></v-text-field>
                <template v-slot:input>
                  <v-text-field v-model="item.deliver_unit_package_fee"></v-text-field>
                </template>
              </v-edit-dialog>
            </div>
          </div>
        </template>
        <template v-slot:item.actions="{ item }">
          <template v-if="target_check_options==check_options.uncheck_tw||target_check_options==check_options.uncheck_cn">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  large
                  class="mr-2"
                  v-on="on"
                  :color="allowCheck(item)?'green':''"
                  :disabled="!allowCheck(item)"
                  @click="checkItem(item)"
                >mdi-check</v-icon>
              </template>
              <span>{{ $t("container-box.check") }}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  large
                  class="mr-2"
                  v-on="on"
                  :disabled="!allowExtra(item)"
                  :color="hasExtra(item)?'green':''"
                  @click="editExtra(item)"
                >mdi-message</v-icon>
              </template>
              <span>{{ $t("container-box.extra") }}</span>
            </v-tooltip>
          </template>
          <template v-else>
            <v-tooltip
              bottom
            >
              <template v-slot:activator="{ on }">
                <v-icon
                  large
                  class="mr-2"
                  v-on="on"
                  @click="unlockItem(item)"
                >mdi-lock-open</v-icon>
              </template>
              <span>{{ $t("container-box.unlock") }}</span>
            </v-tooltip>
        </template>
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>
<script>
import BaseTable from "./BaseTable.vue";
import { check_status, check_options, container_box_status, payment_types } from "@/definition.js";
import CommonAutocomplete from "../filters/CommonAutocomplete";
import DeliverTypeFastCreateDialog from "../dialogs/DeliverTypeFastCreateDialog";
import DeliverSujectFastCreateDialog from "../dialogs/DeliverSujectFastCreateDialog";
import DeliverFeeExtraDialog from "../dialogs/DeliverFeeExtraDialog";
import VueEasyLightbox from 'vue-easy-lightbox'
import DeliverfeeVerifyFilter from '../filters/DeliverFeeVerifyFilter'
import DeliverFeeVerifyDialogVue from '../dialogs/DeliverFeeVerifyDialog';
export default {
  data: (vm) => ({
    url: "/container/container_box/",
    headers: [
      {
        sortable: true,
        text: vm.$i18n.t("container.code"),
        value: "code",
        width: "1%",
      },
      {
        sortable: true,
        text: vm.$i18n.t("container.box_str"),
        value: "start_box",
        width: "1%",
      },
      {
        sortable: false,
        text: vm.$i18n.t("container.number_str"),
        value: "end_box",
        width: "1%",
      },
      {
        sortable: true,
        text: vm.$i18n.t("commodity.name"),
        value: "commodity_name",
      },
      {
        sortable: true,
        text: vm.$i18n.t("commodity.number"),
        value: "commodity_number",
      },
      {
        sortable: false,
        text: vm.$i18n.t("commodity.image"),
        value: "image",
      },
      {
        sortable: true,
        text: vm.$i18n.t("commodity.customs-type")+"("+vm.$i18n.t("commodity.customs-type-hint")+")",
        value: "customs_type",
      },
      {
        sortable: false,
        text: vm.$i18n.t("container.total_amount"),
        value: "total_amount",
      },
      {
        sortable: true,
        text: vm.$i18n.t("container.size_length"),
        value: "size_length",
      },
      {
        sortable: true,
        text: vm.$i18n.t("container.size_width"),
        value: "size_width",
      },
      {
        sortable: true,
        text: vm.$i18n.t("container.size_height"),
        value: "size_height",
      },
      {
        sortable: true,
        text: vm.$i18n.t("container.cubic_feet"),
        value: "cubic_feet",
      },
      {
        sortable: true,
        text: vm.$i18n.t("container.total_gross_weight"),
        value: "total_gross_weight",
      },
      {
        sortable: true,
        text: vm.$i18n.t("supplier.supplier"),
        value: "supplier",
      },
      {
        sortable: true,
        text: vm.$i18n.t("customer.customer"),
        value: "customer__name_tc",
      },    
      // {
      //   sortable: true,
      //   text: vm.$i18n.t("commodity.deliver-type"),
      //   value: "deliver_type",
      // },
      {
        sortable: true,
        // text: vm.$i18n.t("commodity.deliver-suject")+"("+vm.$i18n.t("commodity.deliver-type-hint")+")",
        text: vm.$i18n.t("commodity.deliver-type"),
        value: "deliver_suject",
      },
      // {
      //   sortable: true,
      //   text: vm.$i18n.t("commodity.deliver-fee"),
      //   value: "deliver_fee",
      // },
      {
        sortable: false,
        text: vm.$i18n.t("commodity.unit"),
        value: "deliver_unit",
      },
      {
        sortable: false,
        text: vm.$i18n.t("actions"),
        value: "actions",
      },
    ],
    check_status: check_status,
    check_options: check_options,
    target_check_options: check_options.uncheck_tw,
    container_box_status: container_box_status,
    payment_types: payment_types,
    deliver_type: null,
    deliver_type_cache: null,
    deliver_suject: null,
    deliver_suject_cache: null,
    commodity: null,
    lightboxVisible: false,
    images: null,
    dialogAction: null,
    defaultItem2: {},
    dialogItem2: {},
    dialogAction2: null,
    showDialog2: false,
    container_items: [],
    showDialog3: false,
    dialogAction3: null,
    dialogItem3: {},
    selected: [],
    showDialog4: false,
    dialogAction4: null,
    dialogItem4: {},
    selectedWeight: 0,
    selectedCubicfeet: 0,
    selectedAmount: 0,
  }),
  mixins: [BaseTable],
  components: {
    CommonAutocomplete,
    VueEasyLightbox,
    DeliverTypeFastCreateDialog,
    DeliverSujectFastCreateDialog,
    DeliverfeeVerifyFilter,
    DeliverFeeExtraDialog,
    DeliverFeeVerifyDialogVue
  },
  computed: {
    nameField() {
      if (this.$i18n.locale === "zhHant") {
        return "name_tc";
      } else {
        return "name_sc";
      }
    },
  },
  methods: {
    get_end_box(item) {
      return item.box_amount > 1 ? item.end_box : "";
    },
    get_total_gross_weight(item) {
      return item.gross_weight * item.box_amount;
    },
    get_supplier_name(item) {
      return this.getHanText(item.supplier, "name");
    },
    get_customer_name(item) {
      return this.getHanText(item.customer, "name");
    },
    get_deliver_type(item) {
      if (item) {
        return this.getHanText(item, "name");
      }
      return "------------";
    },
    getColor(val) {
      console.log("val", val);
      var color = "";
      switch (val) {
        case undefined:
        case 0:
          color = "red";
          break;
        default:
          color = "green";
          break;
      }
      return color;
    },
    allowCheck(item){
      if (item.deliver_suject && this.deliverFeeValidate(item)) {
        return true
      }
      return false
    },
    checkItem(item) {
      console.log("checkItem", item);
      this.updateField(item.id, "check_status", this.check_status.check);
    },
    deliverFeeValidate(item){
      if (item.deliver_unit_amount && item.deliver_unit_amount_fee>0) {
        return true
      }
      if (item.deliver_unit_weight && item.deliver_unit_weight_fee>0) {
        return true
      }
      if (item.deliver_unit_cubicfeet && item.deliver_unit_cubicfeet_fee>0) {
        return true
      }
      if (item.deliver_unit_package && item.deliver_unit_package_fee>0) {
        return true
      }
      return false
    },
    unlockItem(item) {
      this.updateField(item.id, "check_status", this.check_status.init);
    },
    editOpen(item, type) {
      console.log("editOpen");
      if (item) {
        if (type === 1) {
          this.deliver_type = item.id;
          this.deliver_type_cache = item;
        } else if (type === 2) {
          this.deliver_suject = item.id;
          this.deliver_suject_cache = item;
        }
      }
    },
    editClose() {
      console.log("editClose");
      this.deliver_type = null;
      this.deliver_type_cache = null;
      this.deliver_suject = null;
      this.deliver_suject_cache = null;
    },
    displayCommodityImage(item) {
      console.log('displayCommodityImage',item)
      var extra = JSON.parse(item.relation[0].commodity.extra)
      this.getImages(extra['images'])
    },
    getImages(images){
      var params = {'id__in':images}
      this.getApi("/document/document/",this.setImages,false,params)
      
    },
    setImages(data){
      this.images = []
      data.results.forEach(img=>{
        this.images.push({
          title: img.name,
          src: img.file
        })
      })
      this.lightboxVisible = true;
    },
    closeLightBox() {
      this.lightboxVisible = false;
    },
    get_customs_type_name(item){
      return item.customs_type?this.getHanText(item.customs_type,"name"):""
    },
    createDeliverType(){
      this.dialogItem = this._.cloneDeep(this.defaultItem);
      this.dialogAction = this.action_items.create;
      this.showDialog = true;
    },
    onSaveEvent(item){
      this.showDialog = false;
      this.postApi(item,"/commodity/deliver-type/")
    },
    createDeliverSuject(){
      this.dialogItem2 = this._.cloneDeep(this.defaultItem2);
      this.dialogAction2 = this.action_items.create;
      this.showDialog2 = true;
    },
    onSaveEvent2(item){
      this.showDialog2 = false;
      this.postApi(item,"/commodity/deliver-suject/")
    },
    onCloseEvent2(item) {
      this.showDialog2 = false;
      this.dialogItem2 = this._.cloneDeep(this.defaultItem2);
    },
    getContainerDatas() {
      let params = {}
      params["status__in"] =this.$common_util.getRange([
        this.container_status.enable,
        this.container_status.lock,
        this.container_status.close,
        this.container_status.invoice]);
      this.getApi("/container/container/", this.setContainerItems,false,{},params,{itemsPerPage:200,sortBy:["id"],sortDesc:[false]});
    },
    setContainerItems(data) {
      this.container_items = []
      this.setListItems(data.results, this.container_items,"stuffing_date", "container_number");
      console.log('this.container_items',this.container_items)
    },
    setListItems(datas, target_items, text_key1 = "name", text_key2 = "", value_key = "id") {
      datas.forEach((data) => {
        target_items.push({
          text: data[text_key1]+" "+data[text_key2],
          value: data[value_key],
        });
      });
    },
    setResult(data) {
      this.totalItems = data.count;
      this.items = data.results;
      this.getContainerDatas()
    },
    onFilterChange(item) {
      console.log('onFilterChange',item)
      // if (item.container==null) {
      //   item['container__isnull'] = true
      // }
      this.filterItem = JSON.parse(JSON.stringify(item));
      this.options.page = 1;
      this.getApi();
    },
    get_image(item) {
      return item.commodity.image ? item.commodity.image.file : "";
    },
    allowExtra() {
      return true
    },
    editExtra(item){
      this.showDialog3 = true;
      this.orig_dialogItem = this._.cloneDeep(item);
      this.dialogItem3 = this._.cloneDeep(item);
    },
    onCloseEvent3(){
      this.showDialog3 = false;
      this.dialogItem3 = this._.cloneDeep(this.defaultItem3);
    },
    onSaveEvent3(item){
      this.showDialog3 = false;
      console.log("onSaveEvent3",item)
      this.patchHandler(item)
    },
    patchHandler(item) {
      console.log("orig_dialogItem " + JSON.stringify(this.orig_dialogItem));
      if (this._.isEqual(item, this.orig_dialogItem) === false) {
        const diff = this.$common_util.difference(this.orig_dialogItem, item);
        console.log("diff: " + JSON.stringify(diff));
        this.patchApi(item.id, diff);
      }
    },
    multipleUpdate(){
      var boxes = [];
      if (this.selected.length > 0) {
        // calculate summary
        this.calculateSelectedSummary()
        for (const [key, box] of Object.entries(this.selected)) {
          boxes.push(box.id);
        }
        this.dialogItem4 = {
          'boxes': boxes,
        }
        this.showDialog4 = true
      } else {
        alert(this.$i18n.t("alert.selected-box-empty"))
      }
    },
    calculateSelectedSummary(){
      console.log('calculateSelectedSummary',this.selected)
      this.selectedAmount = 0
      this.selectedCubicfeet = 0
      this.selectedWeight = 0
      for (const[key,item] of Object.entries(this.selected)) {
        // console.log(item)
        this.selectedAmount += this.get_total_amount(item)
        this.selectedCubicfeet += item.cubic_feet
        this.selectedWeight += this.get_total_gross_weight(item)
      }
      // console.log(this.selectedAmount,this.selectedCubicfeet,this.selectedWeight)
    },
    onCloseEvent4(){
      this.showDialog4 = false;
    },
    onSaveEvent4(item){
      this.showDialog4 = false
      this.selected = []
      this.postApi(item, "/container/container_box/multipleUpdateDeliverInfo/",this.afterUpdate);
    },
    afterUpdate(action, responseData) {
      // this.selected = []
      this.dialogItem4 = {}
      this.getApi();
    },
    get_total_amount(item) {
      let total_amount = 0
      for (const [key, rel] of Object.entries(item['relation'])) {
        total_amount += item['box_amount'] * rel['commodity_amount']
      }
      return total_amount
    },
    hasExtra(item){
      if (item.extra) {
        let extra = JSON.parse(item.extra)
        if (Object.keys(extra).includes('extra_fees')) {
          let extra_fees = extra['extra_fees']
          return extra_fees.length>0
        }
      }
      
      return false
    },
  },
  watch: {
    target_check_options(val) {
      console.log('target_check_options',val)
      this.getParams["check_status"] = val===this.check_options.check?this.check_status.check:this.check_status.init;
      delete this.getParams["payment_type"]
      delete this.getParams["payment_type__in"]
      if (val==this.check_options.uncheck_tw) {
        this.getParams["payment_type"] = 1;
      } else if (val==this.check_options.uncheck_cn) {
        this.getParams["payment_type__in"] = '2,3';
      } else if (val === this.check_options.check) {
        this.options = {
          sortBy: ["check_time"],
          sortDesc: [false],
        };
      }
      this.getApi();
    },
  },
  mounted() {
    this.getParams["check_status"] = this.check_status.init;
    this.getParams["payment_type"] = 1;
    this.getParams["status__lt"] = this.container_box_status.disable
    this.footerProps.itemsPerPageOptions = [20, 50, 100, 200]
    this.options = {
      sortBy: ["customer__name_tc","code","start_box"],
      sortDesc: [true,true,true],
      itemsPerPage: 200
    };
  },
};
</script>
