<template>
  <v-dialog
    v-model="show"
    :max-width="max_width"
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row dense>
            <v-col v-if="isTraditional">
              <v-text-field
                v-model="item.name_tc"
                :label="$t('name_tc')"
              >
                <template
                  #label
                  v-if="action==action_items.create"
                >
                  <span class="red--text"><strong>* </strong></span>{{ $t('name_tc') }}
                </template>
              </v-text-field>
            </v-col>
            <v-col v-else>
              <v-text-field
                v-model="item.name_sc"
                :label="$t('name_sc')"
              >
                <template
                  #label
                  v-if="action==action_items.create"
                >
                  <span class="red--text"><strong>* </strong></span>{{ $t('name_sc') }}
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="close">{{ $t("cancel") }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-if="action != action_items.view"
          color="primary"
          :disabled="allowSave"
          @click="save"
        >{{ $t("save") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import BaseDialog from "./BaseDialog";
export default {
    data: (vm)=>({
        topic: vm.$i18n.t("commodity.deliver-type"),
    }),
    computed: {
        allowSave() {
      return !(
        this.item.name_tc ||
        this.item.name_sc
      );
    },
    },
    mixins: [BaseDialog],
}
</script>