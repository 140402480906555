<template>
  <v-dialog
    v-model="show"
    :max-width="max_width"
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row dense>
            <v-col>
              <v-card>
                <v-card-text>
                  <div>{{ $t('container-box.remark') }}</div>
                  <v-row dense>
                    <v-col>
                      <v-text-field
                        v-model="remark"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-card>
                <v-card-text>
                  <div>{{ $t('container-box.extra-fees') }}</div>
                  <v-row v-for="(item,index) in extraFees" :key="index" dense>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="item.type"
                        :label="$t('container-box.extra-type')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-select
                      v-model="item.unit"
                      :items="deliver_fee_unit_items"
                      :item-text="item => $t(item.text)"
                      :label="$t('container-box.extra-unit')"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field
                        v-model="item.fee"
                        type="number"
                        :label="$t('container-box.extra-fee')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="1">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            @click="addExtraFee()"
                            v-on="on"
                          >mdi-plus</v-icon>
                        </template>
                        <span>{{ $t("container-box.add-extra-fees") }}</span>
                      </v-tooltip>
                    </v-col>
                    <v-col cols="12" md="1">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            :disabled="((index==extraFees.length-1)&&(item.type==''))"
                            @click="removeExtraFee(index)"
                            v-on="on"
                          >mdi-minus</v-icon>
                        </template>
                        <span>{{ $t("container-box.remove-extra-fees") }}</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="close">{{ $t("cancel") }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-if="action != action_items.view"
          color="primary"
          :disabled="allowSave"
          @click="save"
        >{{ $t("save") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { deliver_fee_unit_items } from "@/definition.js"
import BaseDialog from "./BaseDialog";
export default {
  data: (vm)=>({
    topic: vm.$i18n.t("container-box.extra"),
    remark: "",
    extraFees: [
      {type: '', fee: ''}
    ],
    deliver_fee_unit_items: deliver_fee_unit_items,
  }),
  computed: {
    allowSave() {
      return false;
    },
  },
  mixins: [BaseDialog],
  methods: {
    addExtraFee(){
      this.extraFees.push({type: '', fee: ''});
    },
    removeExtraFee(index){
      this.extraFees.splice(index, 1);
    },
    save(){
      // console.log('save',this.item)
      let extra = JSON.parse(this.item.extra)
      console.log('extra',extra)
      console.log('remark',this.remark)
      if (this.remark) {
        extra['remark'] = this.remark
      }
      console.log('extraFees',this.extraFees)
      if (this.extraFees) {
        this.validateExtraFees()
        extra['extra_fees'] = this.extraFees
      }
      this.item.extra = JSON.stringify(extra)
      console.log(this.item)
      this.remark = null;
      this.extraFees = [
        {type: '', unit: null, fee: ''}
      ]
      this.$emit('onSave',this.item)
    },
    close() {
      this.remark = null;
      this.extraFees = [
        {type: '', unit: null, fee: ''}
      ]
      this.$emit("onClose");
    },
    validateExtraFees(){
      let validate_datas = []
      for (const[key,data] of Object.entries(this.extraFees)){
        if (data['type'] && data['unit'] && data['fee']) {
          validate_datas.push(data)
        }
      }
      this.extraFees = validate_datas
    }
  },
  watch: {
    show:{
      handler(val){
        if (val) {
          if (this.item.extra) {
            console.log('has extra',this.item.extra)
            let extra = JSON.parse(this.item.extra)
            if (Object.keys(extra).includes('remark')) {
              console.log('parse remark')
              this.remark = extra['remark']
            }
            if (Object.keys(extra).includes('extra_fees')) {
              console.log('parse extraFees')
              this.extraFees = extra['extra_fees']
              this.extraFees.push({type: '', fee: ''});
            }
          }
        }
      },
    }
  },
}
</script>