<template>
  <v-container id="kontainer-box.filter" fluid tag="section">
    <!-- time -->
    <v-row>
      <v-col>
        {{ $t("container.stuffing-date") }}
        <CommonDateFilter v-on:setTimeRange="setTimeRange"></CommonDateFilter>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <v-select
          v-model="filterItem.container"
          :label="$t('container.container')"
          :items="container_items"
          outlined
          dense
          clearable
          :clear-icon-cb="onChange"
          :item-text="(item) => $t(item.text)"
        ></v-select>
      </v-col>
      <!-- customer -->
      <v-col>
        <CommonAutocomplete
          v-model="filterItem.customer"
          :label="$t('customer.customer')"
          url="/customer/customer/"
          :item_text="nameField"
          item_value="id"
          :query_field="nameFieldFilter"
          :extra_filter="filterItem"
          :extra_condition="{
            enable: true
          }"
        ></CommonAutocomplete>
      </v-col>
      <!-- deliver-type -->
      <v-col>
        <CommonAutocomplete
          v-model="filterItem.deliver_suject"
          :label="$t('commodity.deliver-type')"
          url="/commodity/deliver-suject/"
          :item_text="nameField"
          item_value="id"
          :query_field="nameFieldFilter"
          :extra_filter="filterItem"
          :extra_condition="{
            enable: true
          }"
        ></CommonAutocomplete>
      </v-col>
      <v-col class="text-right">
        <v-btn color="primary" dark class="mb-2" @click="onChange">
          <v-icon left>mdi-magnify</v-icon>
          {{ $t("search") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import BaseFilter from "./BaseFilter";
import CommonDateFilter from "./CommonDateFilter";
import CommonAutocomplete from "./CommonAutocomplete";
export default {
  props: {
    container_items: [],
  },
  data: (vm) => ({
    filterItem: {
      container: null,
      container__stuffing_date__range: {
        start: new Date(),
        end: new Date(),
      },
      customer: null,
    },
  }),
  mixins: [BaseFilter],
  components: {
    CommonDateFilter,
    CommonAutocomplete,
  },
  methods: {
    setTimeRange(val) {
    //   console.log("setTimeRange");
    //   console.log(val);
      this.timeRange = val;
    },
    onChange() {
      this.filterItem.container__stuffing_date__range = this.timeRange;
      this.$emit("onFilterChange", this.filterItem);
    },
  },
};
</script>
